import React from 'react'
import { graphql } from 'gatsby'

//content
import Seo from '../components/SEO'
import RichContent from '../components/content/RichContent'
//scss
import '../scss/page/page.scss'
const TemplatePage = ({ data, pageContext, location }) => {
  const { content } = data.c

  return (
    <>
      <Seo title={pageContext.title} slug={location.pathname} />
      <div className='page'>
        <div className='container'>
          <RichContent myRichContent={content} />
        </div>
      </div>
    </>
  )
}

export const query = graphql`
  query ($id: String) {
    c: contentfulPage(id: { eq: $id }) {
      content {
        raw
      }
    }
  }
`

export default TemplatePage
